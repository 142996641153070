import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import banner_5 from '../images/banners/banner-5.jpg'

export const AboutPageTemplate = ({ location, siteTitle, title, content, contentComponent, photo }) => {
  const PageContent = contentComponent || Content

  return (
    <Layout location={location}>
      <Helmet title={`${title} | ${siteTitle}`} />

      <div id="top-banner" className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-xs-12">
            <img src={banner_5} alt="À propos" className="full-width-image" />
          </div>
        </div>
      </div>

      <div id="breadcrumbs-container" className="container-fluid horizontal-section-container hidden-xs clearfix">
        <div className="row">
          <div className="col-xs-12">
            <ol className="breadcrumb">
              <li><Link to="/">Accueil</Link></li>
              {/* eslint-disable-next-line */}
              <li className="active"><a href="#">À propos</a></li>
            </ol>
          </div>
        </div>
      </div>

      <div className="container-fluid horizontal-section-container clearfix">
        <div className="row">
          <div className="col-sm-12">
            <div id="main-page-content" className="section-container main-page-content clearfix">
              <div className="section-content">
                <h1 className="page_title">{title}</h1>
                <div className="col-sm-5">
                  <Img fluid={photo.childImageSharp.fluid} />
                </div>
                <div className="col-sm-7">
                  <PageContent className="content" content={content} />
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

AboutPageTemplate.propTypes = {
  sitetitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ location, data }) => {
  const { markdownRemark: post, site: { siteMetadata: { title: siteTitle } } } = data

  return (
    <AboutPageTemplate
      location={location}
      siteTitle={siteTitle}
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      photo={post.frontmatter.photo}
    />
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        photo {
          childImageSharp {
            fluid(maxWidth: 1068) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
